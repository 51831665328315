.row{
  display: flex;
  flex: 1;
}
.item{
  display: flex;
  align-items: center;
  padding-top: 8px;
  flex: 1;
}
.item:last-child{
  justify-content: flex-end;
}
.itemSum{
  display: flex;
  align-items: center;
  padding-top: 8px;
  flex: 1;
}
.payouts{
  display: block;
  width: 100%;
  float: left;
}
.placeholder{
  width: 100%;
  float: left;
}
.stats{
  width: 100%;
  display: block;
  float: left;
}
.statRow{
  display: flex;
  flex: 1;
}

.t div{
  margin: 0;
}