.wideInput {
  flex: 3;
}

.NarrowInput {
  flex: 2;
}

@media screen and (min-width: 560px){
  .wideInput{
    flex: 3;
  }
  .NarrowInput {
    flex: 2;
  }
}

input.input{
    color:black;
}

.fieldSetRow .wideInput {
    border-right: 0;
}

.fieldSetRow .save{
    width: 50px;
    height: 30px;
    padding: 0;
	float: right;
    border: 0;
    font-size: 12px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.fieldSetRow .save:hover{
	text-decoration: underline;
}

.viewTrustlyDetails {
	width: 100%;
	height: auto;
	display: block;
    float: left;
	box-sizing: border-box;
	border-radius: 5px;
	background-color: #FAFAFA;
	padding: 9px;
	position: relative;
}
.viewTrustlyDetails span{
	float: left;
	line-height: 30px;
    font-size: 12px;
    margin-left: 10px;
}
.viewTrustlyDetails i.bankType{
	left: 0;
	top: 0;
	right: 0;
	position: relative;
	float: left;
}

.viewTrustlyDetails .edit{
	position: absolute;
	right: 10px;
	line-height: 30px;
	font-size: 12px;
	color: var(--primary);
	text-decoration: none;
}
.viewTrustlyDetails .edit:hover{
	text-decoration: underline;
}

.errorMessage {
  width: 15px;
  height: 15px;
}
.errorMessage img{
  width: auto;
  height: 100%;
}

i.bankType{
    width:50px;
	position: absolute;
    left: 9px;
    top: 9px;
	border-radius: 3px;
	transition-duration: 0.2s;
	padding: 0px 5px;
    height: 30px;
    background-size: contain;
	background-repeat: no-repeat;

	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	line-height: 30px;
	text-align: center;
	font-size: 10px;

    color: #fff;
}

/*
 * BANKS
 */
.bankType{
   color: #fff;
   background-color: #005FA0;
 }
.handelsbanken{
   background-color: #005FA0;
 }
.alandsbanken{
   background-color: #006940;
 }

.swedbank{
   background-color: #000;
   color: #EF9321;
 }
.nordea, .nordea---personkonto{
   background-color: #0000A0;
 }
.lansforsakringar-bank{
   background-color: #fff;
   color: #005AA0;
 }
.avanza-bank{
   background-color: #fff;
   color: #00C281;
 }
.citibank{
   background: linear-gradient(#51BAEC, #006D9E);
   color: #fff;
 }
.danske-bank{
   background-color: #003755;
 }
.forex-bank{
   background-color: #FED724;
   color: #000;
 }
.ica-banken{
   background-color: #fff;
   color: #EE3127;
 }
.ikano-banken{
   background-color: #E31C19;
 }
.seb{
   background-color: #60CD18;
 }
.marginalen-bank{
   background-color: #EE3127;
   color: #fff;
 }
.santander-consumer-bank{
   background-color: #FF0000;
 }

.sbab{
   background-color: #fff;
   color: #F36C40
 }
.skandiabanken{
   background-color: #fff;
   color: #00896B
 }