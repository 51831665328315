.drop{
  position: absolute;
  top: 47px;
  left: 0px;
  right: 0px;
  z-index: 10;
  max-height: 245px;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  border-bottom: 1px solid #E8E8E8;
  border-top: 1px solid #f4f4f4;
  padding: 6px;
  box-shadow: 0 10px 11px rgba(0,0,0,0.1);
  opacity: 0;
	visibility: hidden;
	transform: translateY(-10px);
	transition: transform 200ms ease-out, opacity 100ms ease-out;
  outline: none;
  padding-top: 26px;
}
.drop.show{
  visibility: visible;
	transform: translateY(0px);
	opacity: 1;
}
.myEmployees{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 20px;
  background-color: #f6f6f6;
  font-size: 12px;
  box-sizing: border-box;
  padding: 0 6px;
  font-weight: 600;
}

.employee{
  padding: 4px 8px;
  border-radius: 6px;
  list-style-type: none;
}
.employee:last-child{
  border-bottom: 0px;
}
.employee:hover{
  background-color: var(--primary-fill-light);
  cursor: pointer;
}
.symbolContainer{
  width: 33px;
  height: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  float: left;
}
.symbol {
  width: 23px;
  height: 23px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
}
.green{
  background-color: #2da66f;
}
.orange{
  background-color: #ffb337;
}
.blue{
  background-color: #2d67a6;
}
.info{
  display: inline-block;
}
.name{
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  display: block;
  line-height: 1.5;
}
.noName{
  font-size: 14px;
  font-style: italic;
}
.cellphone{
  display: block;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
  color: #333;
}