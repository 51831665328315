.headerParent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;
}

.headerBox {
    flex: 1 0 0;
    margin: 0 10px;
    min-width: 170px;
}

@media screen and (max-width: 991px){
    .headerParent {
        margin: 0;
    }
}

@media screen and (max-width: 720px){
    .headerParent {
         margin: 0 -10px;
    }
}

@media screen and (max-width: 800px) {
    .headerBox {
        max-width: calc(33.33% - 2 * 10px);
    }
}

@media screen and (max-width: 590px) {
    .headerBox {
        max-width: calc(50% - 2 * 10px);
    }
}