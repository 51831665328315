.summary{
  width: 100%;
	display: block;
	float: left;
	margin: 10px auto;
}

.summarySum{
	font-size: 28px;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	color: #000;
	text-align: center;
}
.summarySubtitle{
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	color: #000;
	text-align: center;
}
.toggleList{
	color: var(--primary);
	text-decoration: none;
  padding-left: 4px;
}
.toggleList:hover{
  text-decoration: underline;
}


ul.details{
	border-radius: 5px;
	border: 1px solid #DFDFDF;
	box-sizing: border-box;
	padding: 8px;
	margin: 0;
	margin-bottom: 10px;
	position: relative;

	max-height: 0px;
	opacity: 0;
	overflow: hidden;
	padding: 0px 8px;
	transition: max-height 300ms ease-out, opacity 150ms ease-out;
}
ul.details.show{
	padding: 8px;
	visibility: visible;
	overflow: visible;
	max-height: 360px;
	opacity: 1;
}
ul.details::after{
	content: '';
	width: 0px;
	height: 0px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #DFDFDF;
	position: absolute;
	bottom: -10px;
	z-index: 2;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
ul.details::before{
	content: '';
	width: 0px;
	height: 0px;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #fff;
	position: absolute;
	bottom: -8px;
	z-index: 3;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
ul.details li{
	border-bottom: 1px dashed #DFDFDF;
	padding: 5px 0;
	font-size: 13px;
	color: #5f5f5f;
}
ul.details li .email{
	color: #a2a2a2;
    font-size: 12px;
}
ul.details li.bold{
	font-weight: bold;
	color: #333;
}
ul.details li .amount{
	float: right;
}
ul.details li:last-child{
	border-bottom: none;
}
.summaryRecipientRow{
  display: flex;
  flex-direction: row;
}
.summaryRecipientHeader{
  font-weight: bold;
  display: flex;
  flex-direction: row;
}
.summaryPerson{
  flex: 3;
}
.summaryNet{
  flex: 1;
}
.summaryTax{
  flex: 1;
}
.summaryGross{
  flex: 1;
  text-align: right;
}