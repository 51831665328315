.exit{
	transform: translateX(0px);
	opacity: 1;
}
.exitActive{
	transform: translateX(-50px);
	opacity: 0;
	transition: transform 400ms ease-out, opacity 300ms ease-out;
}
.enter {
	opacity: 0;
	z-index: 1;
	transform: translateX(100px);
}
.enterActive {
	opacity: 1;
	transform: translateX(0px);
	transition: opacity 400ms ease-out, transform 500ms ease-out;
}

.pane{
  width: 100%;
  position: absolute;
  top: 0px;
  min-height: 350px;
  padding-bottom: 25px;
}

@media screen and (max-width: 620px){
  .pane {
    padding-bottom: 100px;
  }
}
