.icon{
  border-radius: 10px;
  border: 1px solid rgba(216,216,216);
  height: 80px;
  width: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  margin-top: 20px;
}
.bankIdCancel {
  display: block;
  margin-bottom: 15px;
}
.infoAlert{
  line-height: inherit !important;
  margin-bottom: 10px;
  padding-top: 5px;
}
.infoAlert span{
  text-decoration: underline;
}
.infoAlert span:hover{
  opacity: 0.7;
  text-decoration: underline;
}