.shield{
  width: 15px;
  height: 15px;
}
.shield img{
  width: auto;
  height: 100%;
}
.shield.medium{
  width: 20px;
  height: 20px;
}
