.thead{
	width: 100%;
	float: left;
	font-weight: bold;
	margin-top: 20px;
	padding-bottom: 5px;
}
.thead .label{
	float: left;
	font-size: 14px;
}
.thead .sum{
	float: right;
}


ul.list{
	display: block;
	width: 100%;
	margin: 10px 0;
	margin-top: 0px;
	padding: 0px;
	float: left;
	border: 1px solid #f1f1f1;
}
ul.list li{
	display: block;
	background-color: #fff;
	padding: 8px 8px;
	width: 100%;
	float: left;
	box-sizing: border-box;
}
ul.list li:nth-child(odd){
	background-color: #FAFAFA;
}
ul.list li .label{
	float: left;
	font-size: 14px;
}
ul.list li .amount{
	float: right;
}

.spec{
  width: 100%;
	display: block;
	float: left;
	position: relative;
}
.specList{

}
ul.specList{
	float: right;
	width: 50%;
	color: gray;
	margin-bottom: 20px;
}
ul.specList li{
	border-bottom: 1px dashed lightgray;
	font-size: 14px;
    padding: 4px 0px;
}
ul.specList .specLabel{

}
ul.specList .specSum{
	float: right;
}
ul.specList .bold{
	font-weight: bold;
	color: #333;
}
.bold{
	font-weight: bold;
	color: #333;
}