.actionBarContainer{
  width: 100%;
  left: 0;
  right: 0;
  bottom: -65px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.15s;
  padding-bottom: env(safe-area-inset-bottom);
}
.show{
  transform: translateY(-121px);
}
.actionBar{
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  box-shadow: 0 6px 14px rgba(0,0,0,0.15);
  display: flex;
  height: 65px;
  flex-direction: column;
}
.col{
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px;
}
.colRight{
  flex: 0;
}
.colMid{
  flex: 0;
}
@media screen and (min-width: 620px){
  .actionBarContainer{
    bottom: -65px;
  }
  .show{
    transform: translateY(-65px);
  }
  .actionBar{
    flex-direction: row;
  }
  .colRight{
    flex: 1;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px){
  .col{
    flex: 2;
  }
  .colRight{
    flex: 2;
  }
  .colMid{
    flex: 1;
  }
}


.barContent{
  margin: auto 0;
}

