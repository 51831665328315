/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.text-align-right{
	text-align: right;
    display: block;
}

h5{
	font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #000;
	width: 100%;
	font-size: 14px;
}
.request-description{
	font-size: 13px;
	color: #333;
}
.request-recipient{
  background-color: #fafafa;
  border-radius: 5px;
  padding: 6px 8px;
  margin: 5px 0 20px;
  line-height: 30px;
  color: #000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #DFDFDF;
  border-radius: 5px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.request-recipient .number{
  display: flex;
  flex-direction: column;
}
.request-recipient .number span{
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: gray;
}
.request-recipient .number b{
  line-height: 1;
  font-weight: normal;
}
.request-recipient .action{
  position: absolute;
  right: 10px;
  line-height: 35px;
  font-size: 12px;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  font-weight: normal;
}
.request-recipient .action:hover{
  text-decoration: underline;
}

.container-fluid{
	width: 100%;    
}
.container-fluid::before{
	content: " ";
	display: table;
}
.container-fluid::after{
	clear: both;
	content: " ";
	display: table;
} 
header.navbar{
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #EDEDED;
	background-color: #fff;
	z-index: 10000;
	transition-duration: 0.2s;
}
header.navbar.hidden{
	transform: translateY(-56px);
}
header.navbar .logo {
  width: auto;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 15px;
  border: 0;
}

@media screen and (max-width: 1200px){
    header.navbar .logo {
        position: static;
		margin-left: -225px;
    }
}

@media screen and (max-width: 1100px){
    header.navbar .logo {
  		display:flex;
		margin-left: 0;
    }
}

@media screen and (max-width: 660px){
    header.navbar .logo {
        position: absolute;
		margin: auto;
		margin-top: 15px;
    }
}

@media screen and (max-width: 660px){

	header{
		height: 48px;
	}
	header .logo{
		height: 23px;
	}
	header .logout{
		margin-top: 3px;
	}
	header.navbar{
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0;
		background-color: #fff;
	}
	header .logout .arrow{
		margin-right: 15px
	}
	header.navbar nav ul {
		position: fixed;
		left: 0px;
		bottom: 0px;
		width: 100%;
		right: 0px;
		height: 56px;
		background-color: #fff;
		margin: 0px;
		border-top: 1px solid #EDEDED;
		display: flex;
		z-index: 9999;
    	padding-bottom: env(safe-area-inset-bottom);
	}
	header.user-not-verified.navbar nav ul{
		display: none;
	}
	header nav ul li a {
		font-size: 13px;
		font-weight: 500;
		line-height: 21px;
		display: block;
	}

	header nav ul li a i{
		display: block;
		font-size: 17px;
		margin-top: 4px;
	}
	header nav ul li {
		flex: 1;
		text-align: center;
	}
}

  /*    position: relative;
    padding-left: 22px;
 
}
ul.main-menu li a span.icon{
  display: inline;
  position: absolute;
  left: 0px;
  top: 1px;
}
ul.main-menu li a span.icon svg{
  display: block;
}
*/
/*
header.navbar nav ul li{
	display: inline;
}
header.navbar nav ul{
  margin: 0;
  height: 50px;
  padding: 0;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}
header.navbar nav ul li{

}
header.navbar nav ul li a{
	padding: 5px 8px;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
}
header.navbar nav ul li a i{
	margin-right: 8px;
}
header.navbar nav ul li a.active{
  color: #000;
  opacity: 1;
}
*/
header.navbar nav ul li.help-menu{
  flex: 0;
  display: none;
}

@media screen and (max-width: 620px){
  header.navbar nav ul li.help-menu{
    flex: 1;
    display: block;
  }
}
.clearfix::after{
	content: "";
    clear: both;
    display: table;
}





.content.center{
	max-width: 480px;
	min-height: 340px;
	margin: 10vh auto;
	position: relative;
}

footer.progress{
	height: 60px;
	width: 100%;
	background-color: #fff;
	border-top: 1px solid rgba(151, 151, 151, 0.17);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
	position: fixed;
	bottom: 0px;
}
footer.progress .progress-container{
	max-width: 800px;
	margin: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}
.action{
	font-weight: 699;
	color: #2F2F91;
	text-decoration: none;
	font-size: 13px;
  cursor: pointer;
}
.action:hover{
  text-decoration: underline;
}

body,html{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    padding-bottom: 60px;
}




ul.buttons{
    margin: 20px 0px;
    margin-bottom: 0px;
    padding: 0px;
    display: flex;
}
ul.buttons li{
    flex: 1;
    display: inline;
    margin: 5px;
}

ul.buttons li a{
    display: block;
    background-color: #fff;
    padding: 18px 10px;
    box-sizing: border-box;
    text-align: center;
    font-size: 15px;
    color: #000;
    font-weight: 700;
    text-decoration: none;
    border-radius: 2px;
    border: 1px solid #C6C6C6;
}

.desc{
	float: left;
    display: block;
    width: 100%;
	color: rgb(98, 98, 98);
	margin: 10px 0;
}
.desc-settings{
	float: left;
    display: block;
    width: 100%;
	color: rgb(98, 98, 98);
	margin: 0px;
	margin-top: 3px;
	margin-bottom: 5px;
}
.hr{
	width: 100%;
	height: 1px;
	background-color: #EDEDED;
	display: block;
	float: left;
	margin: 15px 0;
}
.button-desc{
    margin-bottom: 10px;
}
.margin-bottom{
	margin-bottom: 20px;
}


i.icon.desc-icon{
	background-image: url('../images/icons/desc.svg');
}
i.icon.bank{
	background-image: url('../images/bank.png');
}
i.icon.mail{
	background-image: url('../images/icons/mail.svg');
}
i.icon.phone{
  background-image: url('../images/icons/phone.svg');
}
i.icon.person{
	background-image: url('../images/icons/person.svg');
}
i.icon.link{
	background-image: url('../images/icons/link.svg');
}
i.icon.cash{
	background-image: url('../images/icons/cash.svg');
}
i.icon.calendar{
  background-image: url('../images/icons/calendar.svg');
}

i.credit-card-type{
	position: absolute;
	right: 10px;
	top: 13px;
	//background: red;
	border-radius: 3px;
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
}
i.credit-card-type.visa{
	background-image: url('../images/icons/visa.svg');
}
i.credit-card-type.mastercard{
	background-image: url('../images/icons/mastercard.svg');
}
i.credit-card-type.amex{
	background-image: url('../images/icons/amex.svg');
}
i.credit-card-input{
	font-size: 22px;
	color: #A8A8A8;
	top: 9px;
	left: 9px;
}
i.expiration-date{
	color: #A8A8A8;
	font-size: 18px;
	left: 11px;
	top: 11px;
}
i.cvv-input{
	color: #A8A8A8;
	font-size: 17px;
	left: 11px;
	top: 13px;
}
.amount-row{
	background-color: var(--primary-fill-light);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.delete-receiver{
	position: absolute;
    right: -7px;
    top: -12px;
    font-size: 20px;
    color: #cacaca;
    background-color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
	border-radius: 20px;
	z-index: 3;
}
.delete-receiver:hover{
	color: gray;
	cursor: pointer;
}

.sum-type-container{
	right: 10px;
	display: flex;
	align-items: center;
	position: absolute;
	top: 0px;
	bottom: 0px;
	z-index: 9;
	
}
.sum-type-container .sum-type .label{
	top: 0px;
  left: 0px;
  line-height: normal;
  color: #333;
	font-size: 12px;
	
	font-weight: 600;
	font-size: 12px;
	color: #333;
	position: relative;
	z-index: 2;
}
.sum-type-container .sum-type{
	position: relative;
	text-decoration: none;
	outline: #0000A0;	
}
.sum-type-container .sum-type:hover{
	cursor: pointer;
}
.sum-type-container .sum-type i{
	font-size: 10px;
	margin-left: 5px;
}
.sum-type-container ul{
	z-index: 1;
	position: absolute;
	right: -5px;
	border: 1px solid #E8E8E8;
	background-color: #fff;

	border-radius: 5px;
	box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.4);

	opacity: 0;
	transform: translateY(-10px);
	max-height: 0px;
	overflow: hidden;
	transition: max-height 150ms ease-out, transform 150ms ease-out, opacity 80ms ease-out;
}
.sum-type-container ul.show{
	max-height: 100px;
  width: 245px;
	overflow: visible;
	transform: translateY(0px);
	opacity: 1;
}
.sum-type-container ul li{
	font-size: 13px;
	box-sizing: border-box;
	padding: 6px 6px;
	padding-right: 15px;
	border-bottom: 1px solid #E8E8E8;
}
.sum-type-container ul li:first-child{
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.sum-type-container ul li:last-child{
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.sum-type-container ul li.selected{
	background-color: #057AFF;
	color: #fff;
}

.sum-type-container ul li:hover{
	background-color: #057AFF;
	color: #fff;
}
.sum-type-container ul li:last-child{
	border-bottom: 0px;

}
.sum-type-container ul li span.title{
	font-weight: 600;
  display: block;
}
.sum-type-container ul li span.subtitle{
  display: block;
  font-size: 12px;
}


fieldset div:last-child{
	border-bottom: 0px;
}

.label-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.circle{
	width: 13px;
	height: 13px;
	border-radius: 13px;
	display: block;
	margin-right: 5px;
	float: left;
	margin-top: 0px;
}

.alignright{
	float: right;
}


.tab-content{
	width: 100%;
}
.tab-content .tab-pane{
	display: none;
}

.tab-content .tab-pane.active{
	display: block;
}

.summary{
	width: 100%;
	display: block;
	float: left;
	margin: 10px auto;
}
.summary-recipient-header{
  font-weight: bold;
  display: flex;
  flex-direction: row;
}
.summary-recipient-row{
  display: flex;
  flex-direction: row;
}
.summary-person{
  flex: 3;
}
.summary-net{
  flex: 1
}
.summary-tax{
  flex: 1;
}
.summary-gross{
  flex: 1;
  text-align: right;
}

.summary .summary-sum{
	font-size: 28px;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	color: #000;
	text-align: center;
}
.summary .summary-subtitle{
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	color: #000;
	text-align: center;
}
.summary ul.summary-details{
	border-radius: 5px;
	border: 1px solid #DFDFDF;
	box-sizing: border-box;
	padding: 8px;
	margin: 0;
	margin-bottom: 10px;
	position: relative;

	max-height: 0px;
	opacity: 0;
	overflow: hidden;
	padding: 0px 8px;
	transition: max-height 300ms ease-out, opacity 150ms ease-out;
}
.summary ul.summary-details.show{
	padding: 8px;
	visibility: visible;
	overflow: visible;
	max-height: 360px;
	opacity: 1;
}
.summary ul.summary-details::after{
	content: '';
	width: 0px;
	height: 0px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #DFDFDF;
	position: absolute;
	bottom: -10px;
	z-index: 2;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
.summary ul.summary-details::before{
	content: '';
	width: 0px;
	height: 0px;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #fff;
	position: absolute;
	bottom: -8px;
	z-index: 3;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
.summary ul.summary-details li{
	border-bottom: 1px dashed #DFDFDF;
	padding: 5px 0;
	font-size: 13px;
	color: #5f5f5f;
}
.summary ul.summary-details li .email{
	color: #a2a2a2;
    font-size: 12px;
}
.summary ul.summary-details li.bold{
	font-weight: bold;
	color: #333;
}
.summary ul.summary-details li .amount{
	float: right;
}
.summary ul.summary-details li:last-child{
	border-bottom: none;
}
.summary .toggle-list{
	color: var(--primary);
	text-decoration: none;
}

.stack-content{
  width: 100%;
  max-width: 620px;
  display: block;
  box-sizing: border-box;
  margin: 8vh auto;
}
@media screen and (min-width: 620px){
  .stack-content{
  }
}

.content{
	width: 940px;
	margin: 8vh auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 620px){
  .content{
    margin: 25px auto;
  }
}

@media screen and (max-width: 920px){
	.content{
		width: 100%;
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 968px){
	input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], select:focus, textarea {
		font-size: 16px !important;
	}
}




/*
	Dashboard
*/

.intro-text{
	font-size: 17px;
	color: #333;
	margin: 16px 0;
	max-width: 500px;
	float: left;
}
.company-create-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 333.33px;
	min-width: 210px;

}
.notice-link{
  text-decoration: none;
  padding: 10px 0;
  color: #333;
}
.notice-link:hover{
  text-decoration: underline
}


.gender{
	width: 25px;
	height: 25px;
	margin-right: 10px;
	float: left;
}




.doc{
  position: relative;
  overflow: hidden;
}
@media (min-width: 568px){
  .doc{
    box-shadow: 0px 0px 13px rgba(157, 157, 157, 0.15);
    border: 1px solid #f1f1f1;
  }
}
.doc .step-container{
  width: 200%;
  display: block;
  transition-duration: 0.2s;
  min-height: 530px;
}
.doc .step-container.step1{
  transform: translateX(0);
}
.doc .step-container.step2{
  transform: translateX(-50%);
}
.doc .step1-container{
  width: 50%;
  float: left;
  box-sizing: border-box;
  position: relative;
  padding: 0px 8px;
  transition-duration: 0.2s;
}
@media (min-width: 568px){
  .doc .step1-container{
     padding: 20px 25px;
  }
}
.doc .step2-container{
  width: 50%;
  float: left;
  position: relative;
  transition-duration: 0.2s;

}
.doc .step-container.step1 .step2-container{
  opacity: 0;
}
.doc .step-container.step2 .step1-container{
  opacity: 0;
}
.doc .step-container.step2 .step2-container{
  opacity: 1;
}
.doc .success-mark{
      width: 80px;
    height: 80px;
    margin: 24px auto 16px auto;
    display: block;
}
.doc .success-message{
  text-align: center;
  max-width: 280px;
  margin: auto;
}
.doc .success-message p{
  margin: 16px auto;
}
.doc ul.success-table{
  padding: 0 10px;
  margin: 24px 0;
  box-sizing: border-box;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  font-size: 14px;
}
.doc ul.success-table li{
  border-bottom: 1px dashed #E2E2E2;
  display: block;
  text-align: left;
  padding: 8px 0;
}
.doc ul.success-table li:last-child{
  border-bottom: 0px;
}
.doc ul.success-table li .success-key{
  
}
.doc ul.success-table li .success-value{
    float: right;
    font-weight: bold;
}

.doc .line{
	height: 0px;
	width: 100%;
	display: block;
	float: left;
	border: 1px solid #DEDEDE;
	
}
.btn-container .btn{
	margin: 0 10px;
}
.btn-container{
	display: flex;
    justify-content: center;
    align-items: center;
	margin-top: 20px;
}
.accept-thead{
	width: 100%;
	float: left;
	font-weight: bold;
	margin-top: 20px;
	padding-bottom: 5px;
}
.accept-thead .employer{
	float: left;
	font-size: 14px;
}
.accept-thead .amount{
	float: right;
}
ul.payout-company-list{
	display: block;
	width: 100%;
	margin: 10px 0;
	margin-top: 0px;
	padding: 0px;
	float: left;
	border: 1px solid #f1f1f1;
}
ul.payout-company-list li{
	display: block;
	background-color: #fff;
	padding: 8px 8px;
	width: 100%;
	float: left;
	box-sizing: border-box;
}
ul.payout-company-list li:nth-child(odd){
	background-color: #FAFAFA;
}
ul.payout-company-list li .employer{
	float: left;
	font-size: 14px;
}
ul.payout-company-list li .amount{
	float: right;
}

ul.payout-company-list li .employer span{
	display: block;
}



ul.payout-summary{
	display: block;
	width: 100%;
	margin: 5px 0;
	padding: 2px;
	box-sizing: border-box;
	float: left;
	font-size: 14px;
}
ul.payout-summary li{
	display: block;
	background-color: #fff;
	border-bottom: 1px dashed lightgray; 
	padding: 4px 0px;
	width: 100%;
	float: left;
	color: #696969;
}
ul.payout-summary li.bold{
	font-weight: 700;
	color: #000;
  
}
ul.payout-summary li.bold small{
  display: block;
  font-weight: normal;
  color: #696969;
  font-size: 12px;
}
.payout-small {
  display: block;
  font-weight: normal;
  color: #696969;
  font-size: 12px;
}

.payout-small.amount {
  display: block;
  font-weight: normal;
  color: #696969;
  font-size: 12px;
  text-align: right;
}

ul.payout-summary li .label{
	float: left;
}
.payout-amount{
	float: right;
  font-weight: 700;
	color: #000;
}
.bank-details-container{
	box-sizing: border-box;
  display: block;
  float: left;
  width: 100%;
	padding-right: 2px;
	margin-bottom: 5px;
}
.payout-date{
	background-color: #FAFAFA;
	border-radius: 5px;
	padding: 6px 8px;
	margin: 5px 0;
	margin-bottom: 20px;
	line-height: 30px;
	color: #000;
	font-weight: 700;
	float: left;
	width: 100%;
	box-sizing: border-box;
}
.payout-date .amount{
	float: right;
}
.terms{
	padding: 8px;
	background-color: #FAFAFA;
	color: #5d5d5d;
	margin: 5px 0;
  float: left;
}
.report-payout{
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  float: left;
}
.report-payout a{
  color: #5A5A5A;
  font-size: 13px;
  text-decoration: none;
}
.report-payout a:hover{
  text-decoration: underline;
}


.done-circle{
	background-color:#f6f6f6; 
	border-radius: 100px; 
	width:150px; 
	height:150px; 
	margin:auto;
	margin-top: 20px; 
	margin-bottom: 20px;
	font-size: 70px;
	text-align: center; 
	line-height: 150px;
}

.terms-box{
	margin-bottom: 15px;
    float: left;
    display: block;
    margin-top: 15px;
    width: 100%;
}



@media screen and (max-width: 620px){
	
	.content.center{
		margin: 20px auto;
    min-height: 400px;
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 20px;
	}
	.step{
		padding-bottom: 100px;
	}

	.desc{
		font-size: 16px;
		margin: 20px auto;
	}
	ul.buttons li a {
		padding: 13px 10px;
		font-size: 14px;
	}
}

.help-link{
  color: #333;
  font-size: 13px;
  text-decoration: none;
}
.help-link:hover{
  text-decoration: underline;
}
.loading-ocr-container{ 
  display: inline;
  color: gray;
  font-weight: normal;
}
.loading-ocr-loader{
  margin-left: 5px !important;
}
.loading-ocr-container{ 
  display: flex;
  justify-content: center;
  align-items: center;
}
.invoice-info-list{
  background-color: #F2F2F1;
  border-radius: 4px;
  float: left;
  box-sizing: border-box;
  width: 100%;
  display: block;
  padding: 10px 0px 10px 30px;
  margin-bottom: 5px;
}
.invoice-info-list li{
  list-style-type: disc;
  line-height: 1.5;
}
.invoice-info{
   max-width: 320px;
   margin: auto;
}
.success-value{
  float: right;
  font-weight: bold;
  color: #000;
}
i.active-invoice-icon{
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  box-shadow: 0 0 3px #4EB55F;
  background-color: #4EB55F;
}



.payment-status{
	float: right;
	font-size: 12px;
	font-weight: 600;
	padding: 4px 0px;
	border-radius: 100px;
	margin-top: 5px;
}
.payment-status.not-accepted{
	//background-color: #FACE87;
	color: #DA8700;
}
.payment-status.accepted{
	//background-color: #dff0d8;
	color: #3ca23c;
}
.payment-status .mobile{
	display: none;
}
.payment-status .desktop{
	display: block;
}
@media screen and (max-width: 420px){
	.payment-status .mobile{
		display: block;
	}
	.payment-status .desktop{
		display: none;
	}
}

.tooltip-container{
	display: inline;
	position: relative;
}
.tooltip-label{
	border-bottom: 1px dashed gray;
	cursor: help;
	margin-left: 3px;
	margin-right: 3px;
}
.tooltip-summay{
	display: block;
	margin: 0px;
	padding: 0px;
	position: absolute;
	top: 30px;
	left: -50px;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 23;
	width: 190px;
	height: auto;
	background-color: #fff;

	visibility: hidden;
	opacity: 0;
	transform: translateY(-10px);
	transition: transform 200ms ease-out, opacity 300ms ease-out;

	border-radius: 5px;
    border: 1px solid #DFDFDF;
	box-sizing: border-box;
	padding: 8px;
}

.tooltip-container:hover .tooltip-summay{
	visibility: visible;
	transform: translateY(0px);
	opacity: 1;
}
.tooltip-summay::before{
	content: '';
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    top: -8px;
    z-index: 26;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.tooltip-summay::after{
	content: '';
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #DFDFDF;
    position: absolute;
    top: -10px;
    z-index: 25;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.tooltip-summay li{
	display: block;
	float: left;
	box-sizing: border-box;

	border-bottom: 1px dashed #DFDFDF;
    padding: 5px 0;
    font-size: 13px;
	color: #5f5f5f;
	text-align: left;
	width: 100%;
	box-sizing: border-box;
	white-space: nowrap;
}
.tooltip-summay li.tooltip-summay-header{
	border-bottom: 0px;
	font-weight: bold;
	border-bottom: 1px solid #DFDFDF;
}
.tooltip-summay li span.tooltip-summay-name{
	width: 110px;
	float: left;
	white-space: nowrap;
  	overflow: hidden;
	text-overflow: ellipsis;
	overflow: hidden;
}
.tooltip-summay li.tooltip-summay-header span.tooltip-summay-name{
	width: 90px;
}
.tooltip-summay li span.tooltip-amount{
	float: right;
	text-align: right;
}
.tooltip-summay li:first-child{
	padding-top: 0px;
}
.tooltip-summay li:last-child{
	padding-bottom: 0px;
	border-bottom: 0px;
}




.invoice-sender{
	float: left;
	padding-top: 5px;
}
.invoice-sender .symbol{
	width: 45px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	background-color: #fff;
	border-radius: 100%;
	display: block;
	float: left;
}
.invoice-sender .name{
	font-size: 20px;
	line-height: 23px;
	float: left;
	margin-left: 20px;
	color: #fff;
}
@media print{
  .invoice-sender .name{
    color: #000;
  }
}
.invoice-sender .name span{
	display: block;
	width: 100%;
	font-size: 14px;
}
.invoice-recipient{
	float: right;
	color: #fff;
	font-size: 13px;
}
.invoice-recipient span{

	font-weight: bold;
}
.invoice-header{
	width: 100%;
	float: left;
}

.invoice-top{
	background-color: #253B84;
	color: #fff;
	float: left;
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #444444;
}
.invoice-top .amount-to-pay{
	display: block;
	float: left;
	width: 100%;
	padding: 0px;
	padding-bottom: 5px;
	border-bottom: 1px solid #E6E6E6;
}
.invoice-top .amount-to-pay b{
	font-family: 'Inter', sans-serif;
  font-weight: 700;
	color: #000;
	font-size: 28px;
	line-height: 35px;
	margin-right: 5px;
}
.invoice-top .amount-to-pay span{
	display: block;
	line-height: 14px;
	width: 100%;
	
}
.invoice-top .amount-to-pay small{
	font-size: 14px;
	color: #444444;
}
.invoice-top summary{
	padding: 20px;
	padding-bottom: 10px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 5px;
	display: block;
	width: 100%;
	margin-top: 20px;
	float: left;

}
.invoice-top summary dl{
	display: block;
	width: 100%;
	float: left;
	line-height: 28px;
	margin-top: 5px;
}
.invoice-top summary dl dt{
	width: 200px;
	float: left;
}
.invoice-top summary dd{
	text-align: right;
	font-weight: bold;
	
}

.invoice-content{
	width: 100%;
	display: block;
	float: left;
	box-sizing: border-box;
	padding: 20px;
}
.invoice-content h2{
	font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}
.invoice-content .spec{
	width: 100%;
	display: block;
	float: left;
	position: relative;
}
.invoice-content .spec ul.spec-list{
	float: right;
	width: 50%;
	color: gray;
	margin-bottom: 20px;
}
.invoice-content .spec ul.spec-list li{
	border-bottom: 1px dashed lightgray;
	font-size: 14px;
    padding: 4px 0px;
}
.invoice-content .spec ul.spec-list .spec-label{

}
.invoice-content .spec ul.spec-list .spec-sum{
	float: right;
}
.invoice-content .spec ul.spec-list .bold{
	font-weight: bold;
	color: #333;
}

.invoice-top .payment-buttons{
	width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 7px;
}
@media screen and (max-width: 650px){
  .invoice-top .payment-buttons{
    grid-template-columns: 1fr;
  }
}
.invoice-top .payment-buttons button{
	display: block;
	float: left;
	margin: 0;
	padding: 0px 20px;
	border: 0px;
	border-radius: 5px;
	box-sizing: border-box;
	background-color: #fff;
	font-weight: 600;
	font-size: 14px;
	line-height: 48px;
	height: 48px;
	cursor: pointer;
	outline: none;
}
.invoice-top .payment-buttons button a{
	color: #000;
	text-decoration: none;
}
.invoice-top .payment-buttons button:hover{
	opacity: 0.9;
}
@media screen and (max-width: 650px){
	.invoice-top .payment-buttons button{
		width: 100%;
		margin: 0px;
		margin-bottom: 15px;
	}
	.invoice-top .payment-buttons button:last-child{
		margin-bottom: 0px;
	}
}
.invoice-top .payment-buttons button span{
	display: flex;
	justify-content: center;
	align-items: center;
}
.invoice-top .payment-buttons button i{
	margin-right: 8px;
    font-size: 19px;
}
.invoice-top .payment-buttons button:first-child{
	margin-left: 0px;
}


@media screen and (max-width: 620px){
	.invoice-sender .symbol{
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
	}
	.invoice-top{
		padding: 15px;
	}
	.invoice-sender .name{
		font-size: 15px;
		line-height: 18px;
		margin-left: 10px;
	}
	
	.invoice-sender .name span{
		font-size: 13px;
	}
	.invoice-top summary{
		padding: 13px;
	}
	.invoice-top summary dl dt{
		width: 100px;
	}
	.invoice-content .spec ul.spec-list{
		width: 100%;
	}

	.invoice-header{
		height: auto;
	}
	.invoice-sender{
		padding-top: 0px;
		width: 100%;
	}
	.invoice-recipient{
		display: none;
	}
}





.qr-code-container{
  
  flex-direction: row;
  margin-top: 10px;
  display: flex;
  position: relative;
  
}
.qr-code{
  flex: 2;
  margin-top: 5px;
}
.qr-code svg{
  box-sizing: border-box;
  margin: 4px;
}
.qr-code-description{
  flex: 3;
  box-sizing: border-box;
  padding: 8px 0 0 8px;
}
.qr-code-description b{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  text-align: left;
  float: left;
}
.qr-code-description p{
  float: left;
  text-align: left;
  font-size: 12px;
}
@media screen and (max-width: 620px){
  .qr-code-container{
    display: none !important;
  }
}

.loading-container{
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
}

.action-link{
  text-align: center;
  display: block;
  padding: 8px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}


.bold-mobile{
	font-weight: normal;
}

.mobileOnly{
	display: none;
}

.tableCell-mobileOnly{
	flex: 0 0 0px !important;
  padding: 0px !important;
  display: none;
  visibility: hidden;
  overflow: hidden;
}
.tableCell-desktopOnly{
  flex: 1;
  visibility: visible;
}
.main-cell{
  flex: 1 !important;
}
@media screen and (max-width: 720px){
  .main-cell{
    flex: 3 !important;
  }
  .mobileOnly{
    display: block;
  }
  .bold-mobile{
    font-weight: bold;
  }
  .tableCell-mobileOnly{
	  flex: 1;
    visibility: visible;
    display: block;;
  }
  .tableCell-desktopOnly{
    flex: 0 0 0px !important;
    padding: 0px !important;
    visibility: hidden;
    overflow: hidden;
  }
}
/*
@media screen and (max-width: 720px){
	.bold-mobile{
		font-weight: bold;
	}
	.tableCell-mobileOnly{
		display: block;
	}
	.tableCell-desktopOnly{
		display: none;
	}
}

.tableCell-mobileOnly{
	flex: 0 0 0px;
  padding: 0px;
  display: none;
  overflow: hidden;
}
.tableCell-desktopOnly{
    flex: 1;
    padding: 0px 4px;
    overflow: visible;
}
@media screen and (max-width: 720px){
	.tableCell-mobileOnly{
		flex: 1;
    padding: 0px 4px;
    overflow: visible;
  }
	.tableCell-desktopOnly{
		flex: 0 0 0px;
    padding: 0px;
    overflow: hidden;
	}
}
*/

.badge{
  right:  0px;
  top: -3px;
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background-color: red;
}
@media print{
  header.navbar{
    display: none !important;
  }
  .invoice-top .payment-buttons{
    display: none;
  }
}

.salary-specification{
	display: block;
  	float: left;
  	text-align: left;
  	font-size: 12px;
}