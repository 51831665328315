.symbol {
  width: 23px;
  height: 23px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
}
.imgAvatar{
  width: 23px;
  height: 23px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.imgAvatar img{
  max-width: 100%;
  max-height: 100%;
  display: block;
}