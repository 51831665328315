.logo{
  width: 90px;
  height: 90px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /* border: 1px solid #d3d3d3; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0 ,0.2);
  border-radius: 5px;
}
.logo img{
  max-width: 70px;
  height: auto;
  display: block;
  font-size: 17px;
  font-weight: 700;
}
.container{
  max-width: 390px;
  margin: auto;
}
.terms{
  float: left;
  display: block;
  text-align: left;
  padding-top: 16px;
}