
.uploadFile{ 
	width: 100%;
	height: 100px;
	background-color: transparent;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	float: left;
	border-radius: 2px;
	border: 2px dashed #C6C6C6;
	margin-top: 10px;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;
	padding: 10px;
  margin-bottom: 20px;
}
.uploadFile .drop{
	opacity: 0.2;
}
.uploadFile i{
	font-size: 18px;
	margin-right: 5px;
}
.card{
  background-color: #fafafa;
  border: 0;
  margin-bottom: 12px;
  box-sizing: border-box;
  padding: 8px 16px ;
  font-size: 13px;
}
ul.requrements{
  list-style: disc;
  padding-left: 20px;
  padding-top: 4px;
  font-size: 14px;
}
ul.requrements li{
  margin-bottom: 4px;
}
ul.requrements li b{
  font-weight: bold;
}
.opt{
  font-size: 13px;
  display: block;
}
.fileDownload{
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 8px;
}
.fileDownload i{
  font-size: 17px;
  margin-right: 5px;
}




ul.fileList{
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    float: left;
    display: block;
    width: 100%;

}
ul.fileList li{
  border-bottom: 1px dashed #DFDFDF;
  padding: 5px 0;
  font-size: 14px;
  color: #5f5f5f;
  list-style-type: none;
  display: flex;
  flex-direction: row;
}
ul.fileList li:last-child{
  border-bottom: none;
} 
ul.fileList li.header div.col{
  font-weight: bold;
  font-size: 14px;
  flex: 1;
}
ul.fileList li .col{
  flex: 1;
  box-sizing: border-box;
  padding: 0 5px;
}
ul.fileList li .symbolCol{
  flex: 0 20px;
  font-size: 19px;
  color: #650000;
}
.editRow{
  flex: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.fileList li .recipientEdit{
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error{
  background-color: #ffdada;
  color: #650000;
}
  /*
}
ul.fileList li.header div.recipientAmount{
  font-weight: bold;
  width: 100px;
  padding-right: 20px;
}
ul.fileList li .recipientName{
  flex: 1;
}
ul.fileList li .recipientName b{
  display: block;
  font-weight: bold;
}
ul.fileList li .recipientName span{
  display: block;
}
ul.fileList li .recipientAmount{
  width: 100px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

*/