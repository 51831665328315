.loading{
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  left: -20px;
  top: 2px;
}
.loadingIndicator{

}
.totalPrice{
  position: relative;
}
.sum{
  margin-bottom: 5px;
  margin-top: 15px;
	float: left;
	width: 100%;
	display: block;
	max-height: 0px;
	overflow: hidden;
	transition-duration: 0.1s;
	opacity: 0;
	padding-bottom: 5px;
}
.sum.show{
	max-height: 230px;
	opacity: 1;
}
.totalSum{
  padding: 5px 0px;
	border-bottom: 1px solid #EEEEEE;
	color: #000;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.totalSum small{
	font-size: 12px;
  font-weight: normal;
  color: #676767;
  font-family: 'Open Sans';
}
.totalSum span{
  float: right;
}
.totalSumSubrow{
  display: flex;
}
.totalSumSubrow .linkContainer{
  flex: 1;
}
.totalSumSubrow .taxContainer{
  flex: 1;
}
.totalSumSubrow .taxContainer span{
  font-size: 12px;
  color: #676767;
  font-family: 'Open Sans';
  float: right;
}


.details{
	max-height: 0px;
	transition-duration: 0.8s;
	margin-top: 0px;
  max-height: 500px;
}
.detailsBar{
	width: 100%;
	height: 14px;
	display: block;
	background-color: purple;
	border-radius: 13px;
	overflow: hidden;
	position: relative;
  margin-top: 10px;
}
.detailsBar div{
	height: 100%;
	display: block;
	float: left;
}
.green{
	background-color: #2DA66F;
}
.red{
	background-color: #F56323;
}
.orange{
	background-color: #FFB337;
}
.blue{
	background-color: #2D67A6;
}
.circle{
	width: 13px;
	height: 13px;
	border-radius: 13px;
	display: block;
	margin-right: 5px;
	float: left;
	margin-top: 0px;
}