.errorMessage {
    width: 15px;
  }

.errorMessage img{
    width: auto;
    height: 100%;
}

.fieldSetRow .save{
  width: 50px;
  height: 30px;
  padding: 0;
  float: right;
  border: 0;
  font-size: 12px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.fieldSetRow .save:hover{
  text-decoration: underline;
}

.viewPayhourDetails {
	width: 100%;
	height: auto;
	display: block;
    float: left;
	box-sizing: border-box;
	border-radius: 5px;
	background-color: #FAFAFA;
	padding: 9px;
	position: relative;
}
.viewPayhourDetails span{
	line-height: 30px;
    font-size: 12px;
    margin-left: 10px;
}

.viewPayhourDetails .edit{
	position: absolute;
	right: 10px;
	line-height: 30px;
	font-size: 12px;
	color: var(--primary);
	text-decoration: none;
}
.viewPayhourDetails .edit:hover{
	text-decoration: underline;
}

.spacing {
	padding-top: 60px;
}