.wideInput {
  flex: 3;
}

.NarrowInput {
  flex: 2;
}

@media screen and (min-width: 560px){
  .wideInput{
    flex: 3;
  }
  .NarrowInput {
    flex: 2;
  }
}

.useButton {
    margin: 8px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
}

.paymentMethodList {
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    display: block;
    float: left;
    width: 100%;
}

.paymentMethodItem {
    display: block;
    float: left;
    width: 100%;
}

.slowTransfer{
  margin-bottom: 2px;
  text-align: center;
  color: #E36722;
  font-size: 12px;
}
.commonError{
  margin-bottom: 2px;
  text-align: center;
  color: #ff0033;
  font-size: 12px;
}

.viewLoadingDetails {
	width: 100%;
	height: auto;
	display: block;
  float: left;
	box-sizing: border-box;
	border-radius: 5px;
	background-color: #FAFAFA;
	padding: 9px;
	position: relative;
}
.viewLoadingDetails .infoPlaceholders { 
    float: left;
    width: 200px;
    height: 20px;
    margin-top: 5px;
    margin-left: 10px;
    background-color: rgb(243, 243, 243)
}
.viewLoadingDetails i.bankType{
	left: 0;
	top: 0;
	right: 0;
	position: relative;
	float: left;
}

.viewLoadingDetails .loader{
	position: absolute;
	right: 15px;
    top: 12px;
}

.bankType{
    width:50px;
	position: absolute;
    left: 9px;
    top: 9px;
	border-radius: 3px;
	transition-duration: 0.2s;
	padding: 0px 5px;
    min-width: 35px;
    height: 30px;
    background-size: contain;
	background-repeat: no-repeat;

	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	line-height: 30px;
	text-align: center;
	font-size: 10px;

    color: #fff;
}

.bankType{
   color: #fff;
   background-color: gray;
 }

.loadingbanken{
   background-color: #005FA0;
 }
