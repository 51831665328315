.tableRow{
  flex-direction: row;
  height: 45px;
  float: left;
  display: flex;
  width: 100%;
}
.td{
  flex: 1;
  align-items: center;
  display: flex;
}
.center div{
  margin: 15px auto;
  margin-bottom: 10px;
}