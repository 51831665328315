.verifyEmailContainer{
  display: flex;
}
.verifyEmailContent{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  text-align: left;
}
.verifyEmailContent b{
  font-size: 15px;
  font-weight: bold;
}
.mailIcon{
  font-size: 40px;
}
.iconContainer{
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.warning{
  margin-top: 16px;
}