.tinyFooter{
  margin-top: 24px;
  padding-top: 8px;;
  border-top: 1px solid #f4f4f4;
  display: block;
  float: left;
  width: 100%;
}
 ul.lang {
   float: right;
 }
 ul.lang li{
  display: inline;
  font-size: 13px;
  margin: 0px 5px;
}
ul.lang li span{
  color: #2F2F91;
  cursor: pointer;
  text-decoration: none;
}
ul.lang li span:hover{
  text-decoration: underline;
}
ul.lang li.selected span{
  color: gray;
  cursor: default;
}
ul.lang li.selected span:hover{
  text-decoration: none;
}
span.c{
  color: gray;
  font-size: 13px;;
}