.uploadFile{ 
	width: 100%;
	min-height: 300px;
	background-color: transparent;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	float: left;
	border-radius: 2px;
	border: 2px dashed #C6C6C6;
	margin-top: 20px;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;
	padding: 10px;
  margin-bottom: 20px;
}
.uploadFile.loading{
  cursor: wait;
  outline: none;
}
.uploadFile .drop{
	opacity: 0.2;
}
.uploadFile i{
	font-size: 18px;
	margin-right: 5px;
}
.instruction{
  padding: 23px 0;
}
.preview{
  display: block;
  margin: 29px auto;
}