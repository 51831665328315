.information{
  display: flex;
  flex-direction: column;
}
.editBtn{
  display: inline-flex;
  margin-left: 7px;
  background-color: #F3F3F3;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.7;
}
.editBtn:hover{
  opacity: 1;
}
.smallTitle{
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  color: #888888;
  margin-left: 8px;
  font-size: 18px;
}
@media screen and (min-width: 620px){
  .information{
    flex-direction: row;
  }
}
.information .item{
  flex: 1;
}
.item strong{
  display: block;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.item span{
  font-size: 13px;
  color: #888888;
  font-weight: 600;
}
.spaceBetween{
  justify-content: space-between;
}
.actionsButtons{
  flex-direction: column;
}
.place{
  margin-top: 3px;
  width: 120px;
}
.noEmail{
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
}
.avatarContainer{
  flex: 0 0 55px;
  padding-top: 5px;
}
.avatarContainer .avatar{
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background-color: #F3F3F3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.avatarIcon{
  font-size: 30px !important;
}
.shieldContainer{
  position: absolute;
  bottom: -9px;
  right: -12px;
}
.userData{
  flex: 6 1;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px){
  .userData{
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 620px){

  .shieldContainer{
    bottom: -2.5px;
    right: -2.5px;
  }
  .avatarContainer{
    flex: 0 0 90px;
    padding-top: 5px;
  }
  .avatarIcon{
    font-size: 50px !important;
  }
  .avatarContainer .avatar{
    width: 80px;
    height: 80px;
  }
  
}
@media screen and (min-width: 721px){
  .userData{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.dateCell{
  flex: 0 1 90px !important;
  padding: 0px 4px !important;
}