.invoiceContainer{
	margin: auto;
	display: block;
	max-width: 680px;
	box-shadow: 0 2px 9px #ECECED;
	border-radius: 5px;
	margin-top: 10vh;
	overflow: hidden;
	position: relative;
}
@media print{
  .invoiceContainer{
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    margin-top: 0px
  }
}
@media screen and (max-width: 650px){
	.invoiceContainer{
		margin-left: 15px;
		margin-right: 15px;
	}
}

.symbol{
	width: 45px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	background-color: #fff;
	border-radius: 100%;
	display: block;
	float: left;
}