.section{
  width: 100%;
  display: block;
  float: left;
  box-sizing: border-box;
  padding: 20px;
}
.title{
	font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}