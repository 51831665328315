.subtitle{
  color: #525252;
  font-size: 17px;
  display: block;
  padding-bottom: 20px;
}
@media screen and (min-width: 992px){
  .subtitle{
    padding-bottom: 0px;
  }
}
.filterBtn{
  padding: 6px 8px;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
  display: inline;
}
.filterBtn .btnlabel{
  margin-left: 5px;
  font-size: 15px;
}
.activeBtn{
  background-color: var(--primary);
  color: #fff;
}
.filterBtn rect{
  fill: currentColor;
}
.filterContainer{
  position: relative;
  display: inline;
   margin: 5px 0;
}
.filterDropDown{
  position: absolute;
  min-width: 290px;
  box-sizing: border-box;
  padding: 10px;
  left: 0;
  top: 30px;
  z-index: 99;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0px 10px rgba(0,0,0,.105), 0 50px 100px -20px rgba(0,0,0,.2), 0 32px 64px -28px rgba(0,0,0,.3), 0 -16px 64px -8px rgba(0,0,0,.024);
  opacity: 0;
	visibility: hidden;
	transform: translateY(-10px);
	transition: transform 200ms ease-out, opacity 100ms ease-out;
}
.visible{
  visibility: visible;
	transform: translateY(0px);
	opacity: 1;
}
.filterDropDown::before{
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0px;
  left: 20px;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
  border-radius: 4px;
  content: '';
  display: block;
}
.filterHead{
  font-weight: medium;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}