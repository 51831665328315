.contextMenuContainer{
  position: absolute; 
  right: 10px;
  top: 50px;
  width: 230px
}
.circleContainer{
	float: left;
	width: 35px;
	height: 40px;
	position: relative;
	margin-top: 0px;
	margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeCircle{
	width: 35px;
	height: 35px;
	background-color: #FFD23E;
	line-height: 35px;
	color: #fff;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	border-radius: 44px;
	cursor: pointer;
	overflow: hidden;
}
.inactiveCircle{
	width: 17px;
	height: 17px;
	border-radius: 100%;
	overflow: hidden;
	position: absolute;
	right: -7px;
	bottom: 0px;
	background-color: #FFD23E;
	box-shadow: 0 0 0 2px #fff;
}
.companyCircle {
	background-color: #4A66C2;
}

.inactiveCircle .happyFace{
	width: 95%;
  height: 95%;
	margin: auto;
	left: 0;
	right: 0;
	position: absolute;
}
.activeCircle .happyFace{
	width: 80%;
    height: 80%;
    margin: 4px auto;
}
.logout{
	display: block;
	position: relative;
	color: #333333;
	text-decoration: none;
}
.trigger{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 8px;
  box-sizing: border-box;
}
.logout .arrow {
  float: left;
  transform: rotate(0deg);
	transition: transform 200ms ease-out;
  width: 17px;
  height: 18px;
  text-align: center;
  margin: 0 10px;
  line-height: 1;
}
.logout .arrow.show{
	transform: rotate(180deg);
}

ul.profileDropdown{
	border: 1px solid #EDEDED;
  width: 230px;
  height: auto;
  background-color: white;
  position: absolute;
  right: 0px;
  top: 50px;
	box-shadow: 0 6px 14px rgba(0,0,0,0.15);
	opacity: 0;
	visibility: hidden;
	transform: translateY(-10px);
	transition: transform 200ms ease-out, opacity 100ms ease-out;
	z-index: 9999;
  color: #515151;
}
ul.profileDropdown.show{
	visibility: visible;
	transform: translateY(0px);
	opacity: 1;
}
ul.profileDropdown li{
	box-sizing: border-box;
	padding: 10px;
  border-bottom: 1px solid #eaeaea;
  font-size: 14px;
}


.userInfo{
	display: block;
	color: #515151;
	width: 100%;
	text-decoration: none;
}
.userName{
  display: block;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #515151;
  text-decoration: none;
}
.userEmail{
	color: #757575;
  font-size: inherit;
  text-decoration: none;
}

@media screen and (min-width: 620px){
  ul.profileDropdown li{
    font-size: 12px;
  }
  .userName{
    font-size: 14px;
  }
  ul.profileDropdown{
    right: 5px;
    box-shadow: 0 2px 8px rgba(0,0,0,.1);
  }
}

.termsLink, .logoutLink{
	padding: 8px 10px;
}
.termsLink a {
	text-decoration: none;
	display: block;
	font-size: inherit;
  color: #999;
  font-weight: 600;
}
.logoutLink a{
	text-decoration: none;
	display: block;
	font-size: inherit;
  color: #313392;
  font-weight: 600;
}
.logoutLink a i{
	font-size: 19px;
  float: right;
  line-height: 17px;
	font-weight: bold;
	margin-left: 10px;
	transform: translateX(-10px);
	transition-duration: 0.2s;
}
.logoutLink:hover, .termsLink:hover{
	background-color: #F1F1FA;
}
ul.profileDropdown .withSublist{
	padding: 0;
}
.logoutLink:hover i{
	transform: translateX(-5px);
}
.subList{
	display: block;
	margin: 0;
	padding: 6px 0;
}
ul.profileDropdown .withSublist .subList li{
	border-bottom: 0;
	font-weight: 600;
	font-size: inherit;
	padding: 0;
	margin: 0;
	color: #515151;
	font-family: 'Open Sans', sans-serif;
	position: relative;
}
ul.profileDropdown .subList li span.a, .subList li a{
	color: #515151;
	text-decoration: none;
	margin: 0;
	padding: 6px 0px;
	padding-right: 5px;
	display: block;
	width: 100%;
	padding-left: 15px;
	box-sizing: border-box;
  font-size: inherit;
}
ul.profileDropdown .subList li span.a:hover, .subList li a:hover{
	background-color: #F1F1FA;
	cursor: pointer;
}
ul.profileDropdown .subList li span.a.disabled{
  color: gray;
}
ul.profileDropdown .subList li span.a.disabled:hover{
  background-color: #fff;
}
ul.profileDropdown .subList li .waiting {
  float: right;
  display: inline;
  font-size: 11px;
}
ul.profileDropdown .subList .modeLabel{
	color: #2F2F91;
}