.navBar {
  display: flex;
  width: 450px;
  padding-left: 16px
}

.mainMenu {
  width: 450px;
  display: flex;
  margin: 0;
  padding: 0;
  height: 50px;
  align-items: center;
  flex-shrink: 0;
}

.mainMenu li{
  margin: 0 7px;
}

.icon{
  margin-right: 4px;
}

.mainMenu li a{
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
}

.mainMenu li a:hover{
  opacity: 1;
}