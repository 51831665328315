.headerContainer{
	background-color: #253B84;
	color: #fff;
	float: left;
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #444444;
}
.symbol{
	width: 45px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	background-color: #fff;
	border-radius: 100%;
	display: block;
	float: left;
}
.header{
	width: 100%;
	float: left;
  display: flex;
  flex-direction: column;
}
.titleWrapper{
  float: left;
  margin-left: 20px;
}
.title{
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  display: block;
}
.subtitle{
  display: block;
  color: #fff;
  width: 100%;
  line-height: 23px;
  font-size: 14px;
}
.titleContent{
  flex: 1;
}
.recipient{
  flex: 1;
  font-size: 13px;
  color: #fff;
}
.recipient span{
  font-weight: bold;
}
@media screen and (min-width: 650px){
  .header{
    flex-direction: row;
  }
  .recipient{
    flex: 0 0 130px;
  }
}

.summary{
  padding: 20px;
	padding-bottom: 10px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 5px;
	display: block;
	width: 100%;
	margin-top: 20px;
	float: left;
}
.amountToPay{
  display: block;
	float: left;
	width: 100%;
	padding: 0px;
	padding-bottom: 5px;
	border-bottom: 1px solid #E6E6E6;
}
.amountToPay b{
	font-family: 'Inter', sans-serif;
  font-weight: 700;
	color: #000;
	font-size: 28px;
	line-height: 35px;
	margin-right: 5px;
}

.amountToPay small{
	font-size: 14px;
	color: #444444;
}

.amountToPayTitle{
  display: block;
  line-height: 14px;
  width: 100%;
}

dl.propList{
	display: block;
	width: 100%;
	float: left;
	line-height: 28px;
	margin-top: 5px;
}
dl.propList dt{
	width: 200px;
	float: left;
}
dl.propList dd{
	text-align: right;
	font-weight: bold;	
}

.btnList{
  width: 100%;
  float: left;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 7px;
}
@media screen and (min-width: 650px){
  .btnList{
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.btnList button:first-child{
  margin-left: 0;
}
.btnList button{
  padding: 0 20px;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
  height: 48px;
  cursor: pointer;
  outline: none;
  margin-top: 5px;
}
.btnList button a{
  color: #000;
  text-decoration: none;
}
.btnList button:hover{
	opacity: 0.9;
}
.btnList button i{
  margin-right: 8px;
  font-size: 19px;
}