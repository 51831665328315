.termsLogin{
	margin-top: 11px;
    color: gray;
    font-size: 13px;
    float: left;
}
.termsLogin a{
	color: gray;
	text-decoration: underline;
}
.termsLogin a:hover{
	color: #000;
}

.bankidLogo{
  width: 85px;
  height: auto;
  margin-top: 20px;
}

.action{
	font-weight: 699;
	color: #2F2F91;
	text-decoration: none;
	font-size: 13px;
  cursor: pointer;
}
.action:hover{
  text-decoration: underline;
}
.switchLogin{
  font-weight: 400;
  color: #333;
  font-size: 14px;
}