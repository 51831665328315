.number-box{
	background-color: var(--primary);
	display: block;
	border-radius: 5px;
	overflow: hidden;
	margin: 16px 0;
	
}

.number-box .loader{
	background-color: var(--primary);
}

.number-box .loader span {
	color: #fff;
}

.number-box .number{
	width: 100%;
	height: 100px;
	float: left;
	display: flex;
	line-height: 100px;
	color: #fff;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 30px;
	box-sizing: border-box;
	padding: 0px 16px;
	position: relative;
	
}

.number-box .label{
	width: 100%;
	height: 44px;
	background-color: var(--primary-fill);
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	box-sizing: border-box;
	display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	padding-right: 10px;
	padding-left: 10px;
}

@media screen and (max-width: 720px){
	.number-box{
		margin: 8px 0;
	}
	.number-box .number{
		height: 75px;
	}
}
