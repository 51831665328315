.permitRow{
  float: left;
  width: 100%;
  display: flex;
  padding: 8px 0;
}
.permitRow .text{
}
.permitRow .opt{
}
.permitRow .opt label{
  margin-left: 16px;
  float: left;
}
.permitRow .opt label input{
  margin-right: 4px;
}