
.subtitle{
  color: #525252;
  font-size: 17px;
  display: block;
  padding-bottom: 20px;
}
@media screen and (min-width: 992px){
  .subtitle{
    padding-bottom: 0px;
  }
}