
.subtitle{
  font-size: 15px;
}
.orange{
  color: orange;
}

.green{
  color: green;
}


.amountLoading{
  margin-top: 14px;
}
.timeline{
  display: block;
  float: left;
  width: 100%;
  position: relative;
}
.timeline::before{
  content: '';
  width: 2px;
  background-color: #f5f5f5;
  position: absolute;
  left: 7px;
  top: 30px;
  bottom: 30px;
}
.timeline li.active:before{
  opacity: 1;
}
.timeline li::before{
  content: '';
  opacity: 0.3;
  width: 12px;
  height: 12px;
  background-color: var(--primary);
  border-radius: 12px;
  position: absolute;
  left: 2px;
  top: 15px;
}
.timeline li{
  position: relative;
  padding-left: 25px;
  box-sizing: border-box;
  color: #cccccc;
  display: block;
  margin: 10px 0;
  width: 100%;
}
.timeline li.active{
  opacity: 1;
  color: #000;
}
.timeline .date{
  font-size: 15px;
  color: gray;
  display: block;
}
.timeline b{
  font-size: 17px;
  font-weight: bold;
}